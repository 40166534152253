export const showData = {
  id: 1371,
  url: 'http://www.tvmaze.com/shows/1371/westworld',
  name: 'Westworld',
  type: 'Scripted',
  language: 'English',
  genres: ['Drama', 'Science-Fiction', 'Western'],
  status: 'Running',
  runtime: 60,
  premiered: '2016-10-02',
  officialSite: 'http://www.hbo.com/westworld',
  schedule: {
    time: '21:00',
    days: ['Sunday'],
  },
  rating: {
    average: 8.5,
  },
  weight: 99,
  network: {
    id: 8,
    name: 'HBO',
    country: {
      name: 'United States',
      code: 'US',
      timezone: 'America/New_York',
    },
  },
  webChannel: null,
  externals: {
    tvrage: 37537,
    thetvdb: 296762,
    imdb: 'tt0475784',
  },
  image: {
    medium:
      'http://static.tvmaze.com/uploads/images/medium_portrait/241/602832.jpg',
    original:
      'http://static.tvmaze.com/uploads/images/original_untouched/241/602832.jpg',
  },
  summary:
    '<p><b>Westworld</b> is a dark odyssey about the dawn of artificial consciousness and the evolution of sin. Set at the intersection of the near future and the reimagined past, it explores a world in which every human appetite, no matter how noble or depraved, can be indulged.</p>',
  updated: 1598950255,
  _links: {
    self: {
      href: 'http://api.tvmaze.com/shows/1371',
    },
    previousepisode: {
      href: 'http://api.tvmaze.com/episodes/1800152',
    },
  },
  _embedded: {
    episodes: [
      {
        id: 869671,
        url:
          'http://www.tvmaze.com/episodes/869671/westworld-1x01-the-original',
        name: 'The Original',
        season: 1,
        number: 1,
        type: 'regular',
        airdate: '2016-10-02',
        airtime: '21:00',
        airstamp: '2016-10-03T01:00:00+00:00',
        runtime: 68,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/78/195475.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/78/195475.jpg',
        },
        summary:
          "<p>A woman named Dolores is a free spirit in the Old West... and unaware that she's actually an android, programmed to entertain rich guests seeking to act out their fantasies in an idealized vision of the 1880s. However, the people in charge soon realize that their androids are acting in ways that they didn't anticipate.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/869671',
          },
        },
      },
      {
        id: 911201,
        url: 'http://www.tvmaze.com/episodes/911201/westworld-1x02-chestnut',
        name: 'Chestnut',
        season: 1,
        number: 2,
        type: 'regular',
        airdate: '2016-10-09',
        airtime: '21:00',
        airstamp: '2016-10-10T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/78/195436.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/78/195436.jpg',
        },
        summary:
          '<p>Bernard suspects that someone is sabotaging the hosts. Meanwhile, the Man continues his search for the deeper game, and Maeve finds herself in a world of hurt... literally.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/911201',
          },
        },
      },
      {
        id: 911204,
        url: 'http://www.tvmaze.com/episodes/911204/westworld-1x03-the-stray',
        name: 'The Stray',
        season: 1,
        number: 3,
        type: 'regular',
        airdate: '2016-10-16',
        airtime: '21:00',
        airstamp: '2016-10-17T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/79/198183.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/79/198183.jpg',
        },
        summary:
          "<p>Bernard continues to investigate Dolores' supposed malfunction, and realizes that he has something unexpected on his hands. Meanwhile, Robert talks about the early days of the park, and Teddy receives a new role and narrative.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/911204',
          },
        },
      },
      {
        id: 911205,
        url:
          'http://www.tvmaze.com/episodes/911205/westworld-1x04-dissonance-theory',
        name: 'Dissonance Theory',
        season: 1,
        number: 4,
        type: 'regular',
        airdate: '2016-10-23',
        airtime: '21:00',
        airstamp: '2016-10-24T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/80/201367.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/80/201367.jpg',
        },
        summary:
          '<p>While Dolores joins William and Logan on their adventure, the Man in Black continues his search for the entrance to the Maze and meets Armistice. Meanwhile, Robert reveals some of his true power to Theresa and advises her to stay out of his way.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/911205',
          },
        },
      },
      {
        id: 927174,
        url: 'http://www.tvmaze.com/episodes/927174/westworld-1x05-contrapasso',
        name: 'Contrapasso',
        season: 1,
        number: 5,
        type: 'regular',
        airdate: '2016-10-30',
        airtime: '21:00',
        airstamp: '2016-10-31T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/81/204265.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/81/204265.jpg',
        },
        summary:
          "<p>Dolores takes the first step on her path of discovery by deciding to write a new story for herself... where she isn't the damsel. Meanwhile, Elise discovers the Woodcutter's secret, and Robert pays the Man a visit.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/927174',
          },
        },
      },
      {
        id: 932057,
        url:
          'http://www.tvmaze.com/episodes/932057/westworld-1x06-the-adversary',
        name: 'The Adversary',
        season: 1,
        number: 6,
        type: 'regular',
        airdate: '2016-11-06',
        airtime: '21:00',
        airstamp: '2016-11-07T02:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/82/207324.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/82/207324.jpg',
        },
        summary:
          '<p>While Maeve convinces Felix to show her "upstairs," Teddy and the Man close in on the maze. Meanwhile, Bernard and Elsie close in on the identity of the person who is stealing secrets from the park, while Lee meets with a Delos executive director.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/932057',
          },
        },
      },
      {
        id: 938404,
        url:
          'http://www.tvmaze.com/episodes/938404/westworld-1x07-trompe-loeil',
        name: "Trompe L'Oeil",
        season: 1,
        number: 7,
        type: 'regular',
        airdate: '2016-11-13',
        airtime: '21:00',
        airstamp: '2016-11-14T02:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/83/209400.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/83/209400.jpg',
        },
        summary:
          '<p>While William and Dolores travel to the unclaimed territories, Charlotte and Theresa make plans to force Robert out of the park using Clementine. Meanwhile, Maeve continues her plans to escape Westworld.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/938404',
          },
        },
      },
      {
        id: 943065,
        url: 'http://www.tvmaze.com/episodes/943065/westworld-1x08-trace-decay',
        name: 'Trace Decay',
        season: 1,
        number: 8,
        type: 'regular',
        airdate: '2016-11-20',
        airtime: '21:00',
        airstamp: '2016-11-21T02:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/84/211554.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/84/211554.jpg',
        },
        summary:
          "<p>Bernard tries to cope with what he did under Robert's orders. Meanwhile, William and Dolores find a town, and Maeve remembers more of her past. Meanwhile, the Man reveals his past to Teddy.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/943065',
          },
        },
      },
      {
        id: 943066,
        url:
          'http://www.tvmaze.com/episodes/943066/westworld-1x09-the-well-tempered-clavier',
        name: 'The Well-Tempered Clavier',
        season: 1,
        number: 9,
        type: 'regular',
        airdate: '2016-11-27',
        airtime: '21:00',
        airstamp: '2016-11-28T02:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/85/213932.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/85/213932.jpg',
        },
        summary:
          '<p>Bernard forces Robert to take him back into his own memories, while the Man meets with Charlotte. Meanwhile, Dolores escapes the Confederado camp and William gives Logan new orders.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/943066',
          },
        },
      },
      {
        id: 943067,
        url:
          'http://www.tvmaze.com/episodes/943067/westworld-1x10-the-bicameral-mind',
        name: 'The Bicameral Mind',
        season: 1,
        number: 10,
        type: 'regular',
        airdate: '2016-12-04',
        airtime: '21:00',
        airstamp: '2016-12-05T02:00:00+00:00',
        runtime: 90,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/86/216453.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/86/216453.jpg',
        },
        summary:
          "<p>Delores finds out the truth about William's fate. Meanwhile, Maeve organizes an escape plan, only to discover that someone else is pulling the strings. And Robert plays the final piece in his grand narrative.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/943067',
          },
        },
      },
      {
        id: 1214222,
        url:
          'http://www.tvmaze.com/episodes/1214222/westworld-2x01-journey-into-night',
        name: 'Journey Into Night',
        season: 2,
        number: 1,
        type: 'regular',
        airdate: '2018-04-22',
        airtime: '21:00',
        airstamp: '2018-04-23T01:00:00+00:00',
        runtime: 74,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/152/381118.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/152/381118.jpg',
        },
        summary:
          '<p>In the aftermath of the host rebellion, the rescue team finds Bernard and the new Head of Operations asks for his help. Meanwhile, Delores promises to show Teddy the truth, and Maeve convinces Lee and Hector to help her find her daughter.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1214222',
          },
        },
      },
      {
        id: 1440915,
        url: 'http://www.tvmaze.com/episodes/1440915/westworld-2x02-reunion',
        name: 'Reunion',
        season: 2,
        number: 2,
        type: 'regular',
        airdate: '2018-04-29',
        airtime: '21:00',
        airstamp: '2018-04-30T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/153/382957.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/153/382957.jpg',
        },
        summary:
          '<p>Dolores looks for allies sympathetic to her cause and shows Teddy the truth of his existence. In the past, Dolores gets her first taste of the outside world and William shows her a weapon of mass destruction.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1440915',
          },
        },
      },
      {
        id: 1440916,
        url:
          'http://www.tvmaze.com/episodes/1440916/westworld-2x03-virtu-e-fortuna',
        name: 'Virtù e Fortuna',
        season: 2,
        number: 3,
        type: 'regular',
        airdate: '2018-05-06',
        airtime: '21:00',
        airstamp: '2018-05-07T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/154/386069.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/154/386069.jpg',
        },
        summary:
          '<p>While Delores makes a deal with the Confederados, Maeve and her group find Felix and Sylvester. Meanwhile, a new Guest comes to Westworld, and Bernard has a meeting of minds with Peter.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1440916',
          },
        },
      },
      {
        id: 1440917,
        url:
          'http://www.tvmaze.com/episodes/1440917/westworld-2x04-the-riddle-of-the-sphinx',
        name: 'The Riddle of the Sphinx',
        season: 2,
        number: 4,
        type: 'regular',
        airdate: '2018-05-13',
        airtime: '21:00',
        airstamp: '2018-05-14T01:00:00+00:00',
        runtime: 71,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/155/387630.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/155/387630.jpg',
        },
        summary:
          "<p>William pays his recuperating father-in-law several visits. Meanwhile, the Man continues to play Robert's game, and Bernard remembers some of his missing time.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1440917',
          },
        },
      },
      {
        id: 1446362,
        url:
          'http://www.tvmaze.com/episodes/1446362/westworld-2x05-akane-no-mai',
        name: 'Akane No Mai',
        season: 2,
        number: 5,
        type: 'regular',
        airdate: '2018-05-20',
        airtime: '21:00',
        airstamp: '2018-05-21T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/155/389365.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/155/389365.jpg',
        },
        summary:
          "<p>While Maeve and her people explore Shogun World, Delores realizes that the Teddy she knows can't be a part of the new world coming.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1446362',
          },
        },
      },
      {
        id: 1453278,
        url:
          'http://www.tvmaze.com/episodes/1453278/westworld-2x06-phase-space',
        name: 'Phase Space',
        season: 2,
        number: 6,
        type: 'regular',
        airdate: '2018-05-27',
        airtime: '21:00',
        airstamp: '2018-05-28T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/156/390990.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/156/390990.jpg',
        },
        summary:
          '<p>Maeve finds her daughter, Delores finds the "real" Teddy, Grace finds her father, and Charlotte and Stubbs find Peter.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1453278',
          },
        },
      },
      {
        id: 1459961,
        url:
          'http://www.tvmaze.com/episodes/1459961/westworld-2x07-les-ecorches',
        name: 'Les Écorchés',
        season: 2,
        number: 7,
        type: 'regular',
        airdate: '2018-06-03',
        airtime: '21:00',
        airstamp: '2018-06-04T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/156/392143.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/156/392143.jpg',
        },
        summary:
          "<p>Charlotte and Bernard review Bernard's memories to discover what happened to the missing control unit. Meanwhile, Robert has a chat with Bernard, and Maeve confronts her former tormentor.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1459961',
          },
        },
      },
      {
        id: 1459788,
        url: 'http://www.tvmaze.com/episodes/1459788/westworld-2x08-kiksuya',
        name: 'Kiksuya',
        season: 2,
        number: 8,
        type: 'regular',
        airdate: '2018-06-10',
        airtime: '21:00',
        airstamp: '2018-06-11T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/157/393811.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/157/393811.jpg',
        },
        summary:
          "<p>Another of Westworld's Host revolutionaries is revealed. Meanwhile, Emily finds the Man and convinces the Ghost Nation to hand him over to her to ensure his suffering.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1459788',
          },
        },
      },
      {
        id: 1459789,
        url:
          'http://www.tvmaze.com/episodes/1459789/westworld-2x09-vanishing-point',
        name: 'Vanishing Point',
        season: 2,
        number: 9,
        type: 'regular',
        airdate: '2018-06-17',
        airtime: '21:00',
        airstamp: '2018-06-18T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/158/395754.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/158/395754.jpg',
        },
        summary:
          "<p>The Man learns that his grip on reality isn't what he thought, as he considers how his wife died. Meanwhile, Dolores continues her trek across the park to the Valley Beyond, and Bernard receives another visit from Robert.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1459789',
          },
        },
      },
      {
        id: 1459790,
        url:
          'http://www.tvmaze.com/episodes/1459790/westworld-2x10-the-passenger',
        name: 'The Passenger',
        season: 2,
        number: 10,
        type: 'regular',
        airdate: '2018-06-24',
        airtime: '21:00',
        airstamp: '2018-06-25T01:00:00+00:00',
        runtime: 90,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/158/397018.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/158/397018.jpg',
        },
        summary:
          '<p>The Man faces a personal reckoning, while Akecheta and the others find the door. Meanwhile, Bernard meets with Robert one last time after choosing how to deal with Dolores.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1459790',
          },
        },
      },
      {
        id: 1789333,
        url:
          'http://www.tvmaze.com/episodes/1789333/westworld-3x01-parce-domine',
        name: 'Parce Domine',
        season: 3,
        number: 1,
        type: 'regular',
        airdate: '2020-03-15',
        airtime: '21:00',
        airstamp: '2020-03-16T01:00:00+00:00',
        runtime: 70,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/246/615760.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/246/615760.jpg',
        },
        summary:
          "<p>If you're stuck in a loop, try walking in a straight line.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1789333',
          },
        },
      },
      {
        id: 1800146,
        url:
          'http://www.tvmaze.com/episodes/1800146/westworld-3x02-the-winter-line',
        name: 'The Winter Line',
        season: 3,
        number: 2,
        type: 'regular',
        airdate: '2020-03-22',
        airtime: '21:00',
        airstamp: '2020-03-23T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/247/619379.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/247/619379.jpg',
        },
        summary:
          '<p>People put up a lot of walls. Bring a sledgehammer to your life.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800146',
          },
        },
      },
      {
        id: 1800147,
        url:
          'http://www.tvmaze.com/episodes/1800147/westworld-3x03-the-absence-of-field',
        name: 'The Absence of Field',
        season: 3,
        number: 3,
        type: 'regular',
        airdate: '2020-03-29',
        airtime: '21:00',
        airstamp: '2020-03-30T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/247/619381.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/247/619381.jpg',
        },
        summary:
          "<p>If you don't like what you see in the mirror, don't blame the mirror.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800147',
          },
        },
      },
      {
        id: 1800148,
        url:
          'http://www.tvmaze.com/episodes/1800148/westworld-3x04-the-mother-of-exiles',
        name: 'The Mother of Exiles',
        season: 3,
        number: 4,
        type: 'regular',
        airdate: '2020-04-05',
        airtime: '21:00',
        airstamp: '2020-04-06T01:00:00+00:00',
        runtime: 70,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/249/623071.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/249/623071.jpg',
        },
        summary: "<p>The truth doesn't always set you free.</p>",
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800148',
          },
        },
      },
      {
        id: 1800149,
        url: 'http://www.tvmaze.com/episodes/1800149/westworld-3x05-genre',
        name: 'Genre',
        season: 3,
        number: 5,
        type: 'regular',
        airdate: '2020-04-12',
        airtime: '21:00',
        airstamp: '2020-04-13T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/251/629355.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/251/629355.jpg',
        },
        summary: '<p>Just say no.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800149',
          },
        },
      },
      {
        id: 1829375,
        url:
          'http://www.tvmaze.com/episodes/1829375/westworld-3x06-decoherence',
        name: 'Decoherence',
        season: 3,
        number: 6,
        type: 'regular',
        airdate: '2020-04-19',
        airtime: '21:00',
        airstamp: '2020-04-20T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/252/632212.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/252/632212.jpg',
        },
        summary: '<p>Do a lot of people tell you that you need therapy?</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1829375',
          },
        },
      },
      {
        id: 1800151,
        url:
          'http://www.tvmaze.com/episodes/1800151/westworld-3x07-passed-pawn',
        name: 'Passed Pawn',
        season: 3,
        number: 7,
        type: 'regular',
        airdate: '2020-04-26',
        airtime: '21:00',
        airstamp: '2020-04-27T01:00:00+00:00',
        runtime: 60,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/254/635599.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/254/635599.jpg',
        },
        summary:
          '<p>A real friend is one who walks in when the rest of the world walks out.</p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800151',
          },
        },
      },
      {
        id: 1800152,
        url:
          'http://www.tvmaze.com/episodes/1800152/westworld-3x08-crisis-theory',
        name: 'Crisis Theory',
        season: 3,
        number: 8,
        type: 'regular',
        airdate: '2020-05-03',
        airtime: '21:00',
        airstamp: '2020-05-04T01:00:00+00:00',
        runtime: 75,
        image: {
          medium:
            'http://static.tvmaze.com/uploads/images/medium_landscape/254/635189.jpg',
          original:
            'http://static.tvmaze.com/uploads/images/original_untouched/254/635189.jpg',
        },
        summary: '<p>Time to face the music.<br /> </p>',
        _links: {
          self: {
            href: 'http://api.tvmaze.com/episodes/1800152',
          },
        },
      },
    ],
  },
};
